<template lang="html">
  <div v-if="!loading">
    <DlgHeader>
      <IxRes>dlgCert.economy.header</IxRes>
    </DlgHeader>
    <p>
      <IxRes>dlgCert.economy.header.info</IxRes>
    </p>

    <DlgCollapsiblePanel
      v-for="(names, section) in sections" :key="section"
      :section="section"
      :count="statistics[section].count" :filled-count="statistics[section].filled"
    >
      <EcologyInputGrid>
        <EconomyInputLine
          v-for="name in names" :key="name"
          :name="name" :value="data[name]"
          :unit="units[name]"
          hide-description
          :integer="section === 'workers'"
          @input="update"
        />
      </EcologyInputGrid>
    </DlgCollapsiblePanel>
  </div>
</template>

<script>
import {mapValues} from 'lodash'

import EconomyInputLine from './forms/elements/EconomyInputLine'
import DlgCollapsiblePanel from './forms/elements/DlgCollapsiblePanel'
import EcologyInputGrid from './forms/elements/EcologyInputGrid'
import DlgHeader from './forms/elements/DlgHeader'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    EconomyInputLine,
    DlgCollapsiblePanel,
    EcologyInputGrid,
    DlgHeader
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    units () {
      return {
        '6119/2': 'ha',
        '6119/5': 'ha',
        '6119/7': 'ha',
        '7001/3': '',
        '7089/3': '',
        '7099/3': '',
        '1020/2': '€ / ha',
        '1021/2': '€ / ha'
      }
    },
    sections () {
      return {
        balance: ['1020/2', '1021/2', '1219/2', '1229/2', '1439/2', '1459/2', '1469/2', '1499/2', '1516/2', '1523/2', '1524/2', '1526/2', '1529/2', '1568/2', '2339/5'],
        investmentSuppliments: ['2351/5', '2352/5', '2353/5', '2354/5', '2355/5', '2357/5', '2371/5', '2372/5', '2373/5', '2374/5', '2375/5', '2377/5', '2381/5', '2449/5', '2459/5'],
        periodForeignYield: ['2460/5', '2461/5', '2462/5', '2463/5', '2487/5', '2488/5', '2489/5', '2492/5', '2493/5', '2494/5', '2495/5', '2496/5'],
        depreciation: ['2802/5', '2803/5', '2804/5', '2805/5', '2809/5', '2840/5'],
        periodForeignExpenses: ['2870/5', '2871/5', '2872/5', '2873/5', '2887/5', '2888/5', '2889/5', '2890/5', '2891/5', '2892/5', '2893/5', '2894/5', '2895/5'],
        irregularFinancialResults: ['2900/5', '2902/5', '2904/5', '2906/5', '2908/5', '2910/5', '2912/5', '2913/5'],
        profitLossAccount: ['2914/5', '2959/5'],
        balanceAddon: ['3996/5'],
        agriculturalArea: ['6119/2', '6119/5', '6119/7'],
        workers: ['7001/3', '7089/3', '7099/3']
      }
    },
    statistics () {
      return mapValues(this.sections, (names, category) => ({
        count: names.length,
        filled: names.filter(name => this.data[name] !== null).length
      }))
    },
    apiUrl () {
      const {orgUnitId, year} = this.$route.params

      return `/api/v2/dlg-cert/${orgUnitId}/economy/${year}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
