<template lang="html">
  <div class="dlg-input-layout">
    <IxCollapsePanel v-model="showDetails">
      <template #title>
        <IxRes>dlgCert.economy.panel.header.{{ section }}</IxRes>
      </template>
      <template #additional-info>
        <div class="count-info" :class="{complete: filledCount === count}">
          {{ filledCount }} / {{ count }}
        </div>
      </template>
      <template #expand-text>
        <IxRes>Common.SR_Common.Expand</IxRes>
      </template>
      <template #collapse-text>
        <IxRes>Common.SR_Common.Collapse</IxRes>
      </template>

      <slot />
    </IxCollapsePanel>
  </div>
</template>

<script>
import IxCollapsePanel from '@components/ix/IxCollapsePanel'

export default {
  components: {
    IxCollapsePanel
  },
  props: {
    section: String,
    count: Number,
    filledCount: Number
  },
  data () {
    return {
      showDetails: false
    }
  }
}
</script>

<style lang="scss" scoped>
.dlg-input-layout {

  & ::v-deep .collapse-panel {
    width: 70%;
  }

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.count-info {
  color: #ff0000;

  &.complete {
    color: #008000;
  }
}
</style>
