<script>
import DlgNumericInput from './DlgNumericInput'
import DlgInputDescription from './DlgInputDescription'

export default {
  functional: true,
  props: {
    name: String,
    value: Number,
    unit: {
      type: String,
      default: '€'
    },
    integer: Boolean,
    hideDescription: Boolean
  },
  render (h, context) {
    const CellWrapper = content => h('div', {
      class: 'cell-wrapper'
    }, content)

    const {
      name,
      unit,
      value,
      integer,
      hideDescription
    } = context.props

    return [
      CellWrapper([h(DlgInputDescription, {
        props: {
          name: name.replace('/', '-')
        }
      })]),
      CellWrapper([h('p',
        name
      )]),
      CellWrapper([
        h(DlgNumericInput, {
          props: {
            value,
            addon: unit,
            integer,
            hideDescription
          },
          on: {
            input: value => {
              if (context.listeners.input) {
                context.listeners.input({name, value})
              }
            }
          }
        })
      ])
    ]
  }
}
</script>

<style lang="scss" scoped>
.cell-wrapper {
  padding: 0.125em 0.5em;
  display: flex;

  &:nth-of-type(6n + 4), &:nth-of-type(6n + 5), &:nth-of-type(6n + 6) {
    background-color: rgba(#000000, 4%);
  }
}
</style>
